@font-face {
  font-family: Roboto;
  src: url("Roboto-Medium.84e26523.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Light.8ced886a.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

* {
  border: 0;
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

:focus, :active, a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 18px;
  line-height: 1;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

a, a:visited, a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: 500;
}

html, body {
  background-color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.wrapper {
  min-height: 100%;
  max-width: 100vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.content {
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.content__section {
  width: 100%;
  margin-top: 175px;
  padding: 0 12.5vw;
}

.title {
  color: #141414;
  text-align: center;
  margin-bottom: 75px;
  font-size: 36px;
  font-weight: 500;
  line-height: 52.2px;
}

@media screen and (min-width: 1440px) {
  .title {
    font-size: calc(1.25vw + 18px);
    line-height: calc(1.8125vw + 26.1px);
  }
}

@media screen and (min-width: 2560px) {
  .title {
    font-size: 50px;
    line-height: 72.5px;
  }
}

.slider {
  position: relative;
}

.slider__container {
  overflow: hidden;
}

.slider__items {
  transition: transform .5s;
  display: flex;
}

.slider_disable-transition {
  transition: none;
}

.slider__item {
  max-width: 100%;
  -webkit-user-select: none;
  user-select: none;
  background-color: fff;
  flex: 0 0 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.slider__control {
  width: 40px;
  color: #fff;
  text-align: center;
  height: 50px;
  background: #0003;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slider__control_hide {
  display: none;
}

.slider__control[data-slide="prev"] {
  left: 0;
}

.slider__control[data-slide="next"] {
  right: 0;
}

.slider__control:hover, .slider__control:focus {
  color: #fff;
  background: #0000004d;
  outline: 0;
  text-decoration: none;
}

.slider__control:before {
  content: "";
  width: 20px;
  height: 20px;
  background: center / 100% 100% no-repeat;
  display: inline-block;
}

.slider__control[data-slide="prev"]:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.slider__control[data-slide="next"]:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.slider__indicators {
  z-index: 15;
  justify-content: center;
  margin: 0 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.slider__indicators li {
  box-sizing: content-box;
  width: 30px;
  height: 5px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff80;
  background-clip: padding-box;
  border-top: 15px solid #0000;
  border-bottom: 15px solid #0000;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
}

.slider__indicators li.active {
  background-color: #ffffffe6;
}

.header-nav-text {
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.1px;
}

@media screen and (min-width: 1440px) {
  .header-nav-text {
    font-size: calc(.892857vw + 5.14286px);
    line-height: calc(1.29464vw + 7.45714px);
  }
}

@media screen and (min-width: 2560px) {
  .header-nav-text {
    font-size: 28px;
    line-height: 40.6px;
  }
}

.header-cmp-name-text {
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
}

@media screen and (min-width: 1440px) {
  .header-cmp-name-text {
    font-size: calc(1.25vw + 2px);
    line-height: calc(1.8125vw + 2.9px);
  }
}

@media screen and (min-width: 2560px) {
  .header-cmp-name-text {
    font-size: 34px;
    line-height: 49.3px;
  }
}

.header-label-1 {
  color: #fff;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26.1px;
}

@media screen and (min-width: 1440px) {
  .header-label-1 {
    font-size: calc(.892857vw + 5.14286px);
    line-height: calc(1.29464vw + 7.45714px);
  }
}

@media screen and (min-width: 2560px) {
  .header-label-1 {
    font-size: 28px;
    line-height: 40.6px;
  }
}

.header-label-2 {
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 36.25px;
}

@media screen and (min-width: 1440px) {
  .header-label-2 {
    font-size: calc(1.16071vw + 8.28572px);
    line-height: calc(1.68304vw + 12.0143px);
  }
}

@media screen and (min-width: 2560px) {
  .header-label-2 {
    font-size: 38px;
    line-height: 55.1px;
  }
}

.header-title-1 {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 43px;
  font-style: normal;
  font-weight: 500;
  line-height: 62.35px;
}

@media screen and (min-width: 320px) {
  .header-title-1 {
    font-size: calc(2.36607vw + 35.4286px);
    line-height: calc(3.4308vw + 51.3714px);
  }
}

@media screen and (min-width: 2560px) {
  .header-title-1 {
    font-size: 96px;
    line-height: 139.2px;
  }
}

.header-contacts-text {
  color: #fff;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26.1px;
}

@media screen and (min-width: 1440px) {
  .header-contacts-text {
    font-size: calc(.892857vw + 5.14286px);
    line-height: calc(1.29464vw + 7.45714px);
  }
}

@media screen and (min-width: 2560px) {
  .header-contacts-text {
    font-size: 28px;
    line-height: 40.6px;
  }
}

.header {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(#15141667, #15141667), url("welcome-optimized.fc875942.png");
  background-size: cover;
  grid-template: "company nav nav" 100px
                 "welcome welcome welcome" 1fr
                 ". . contacts" 100px
                 / auto 1fr auto;
  display: grid;
  position: relative;
}

.header__company {
  flex-direction: column;
  grid-area: company;
  justify-content: center;
  padding-left: 5vw;
  display: flex;
}

.header__company > :first-child {
  margin-top: 14px;
}

.header__nav {
  grid-area: nav;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5vw;
  display: flex;
}

.header__nav > a {
  text-align: center;
  margin: 1em;
}

.header__welcome {
  flex-direction: column;
  grid-area: welcome;
  justify-content: center;
  align-items: center;
  padding: 0 2em;
  display: flex;
}

.header__welcome > div {
  text-align: center;
  max-width: 700px;
}

.header__welcome :nth-child(1) {
  margin-bottom: 20px;
}

.header__welcome :nth-child(2) {
  max-width: 550px;
  border-top: 1px solid #ffffff73;
  padding-top: 20px;
}

.header__scroll-button {
  width: 44px;
  height: 28px;
  cursor: pointer;
  background-image: url("scroll-button.9c3c2249.svg");
  margin-bottom: -14px;
  margin-left: -22px;
  position: absolute;
  bottom: 50px;
  left: 50%;
}

.header__contacts {
  flex-direction: column;
  grid-area: contacts;
  justify-content: center;
  padding-left: 35px;
  padding-right: 5vw;
  display: flex;
}

.contacts__icon {
  position: relative;
}

.contacts__icon--phone:before {
  width: 15px;
  height: 15px;
  content: url("phone-icon.7d92ca09.svg");
  position: absolute;
  left: -20px;
}

.contacts__icon--email:before {
  width: 15px;
  height: 15px;
  content: url("email-icon.4e6c76dc.svg");
  position: absolute;
  left: -20px;
}

@media (max-width: 768px) {
  .header {
    grid-template-columns: 1fr;
    grid-template-areas: "company"
                         "welcome"
                         "contacts";
  }

  .header__nav {
    display: none;
  }

  .header__welcome :nth-child(2) {
    margin: 0 20px;
  }

  .header__company {
    padding: 0;
  }

  .header__company > div {
    text-align: center;
  }

  .header__scroll-button {
    display: none;
  }

  .header__contacts {
    justify-content: center;
    align-items: center;
    padding: 0 0 0 30px;
  }

  .header__contacts > div {
    text-align: center;
  }
}

@media (min-width: 1400px) {
  .header__welcome > div {
    min-width: 1000px;
    min-width: 800px;
  }
}

@media (min-width: 1900px) {
  .header__welcome > div {
    min-width: 900px;
  }
}

@media (min-width: 2250px) {
  .header__welcome > div {
    min-width: 1000px;
  }
}

@media (min-width: 1600px) {
  .contacts__icon--phone:before, .contacts__icon--email:before {
    width: 20px;
    height: 20px;
    left: -28px;
  }

  .header__scroll-button {
    width: 66px;
    height: 42px;
    margin-bottom: -25px;
    margin-left: -33px;
    bottom: 75px;
    left: 50%;
  }
}

@media (min-width: 2000px) {
  .contacts__icon--phone:before {
    width: 20px;
    height: 20px;
    left: -28px;
  }

  .contacts__icon--email:before {
    width: 23px;
    height: 23px;
    left: -28px;
  }
}

@media (min-width: 2560px) {
  .header {
    grid-template-rows: 150px 1fr 150px;
  }

  .header__scroll-button {
    width: 99px;
    height: 63px;
    margin-bottom: -32.5px;
    margin-left: -49.5px;
    bottom: 112.5px;
    left: 50%;
  }

  .contacts__icon--phone:before, .contacts__icon--email:before {
    width: 30px;
    height: 30px;
    left: -35px;
  }
}

.about-counter-text {
  text-align: center;
  color: #141414;
  font-size: 30px;
  font-weight: 500;
  line-height: 43.5px;
}

@media screen and (min-width: 1440px) {
  .about-counter-text {
    font-size: calc(1.25vw + 12px);
    line-height: calc(1.8125vw + 17.4px);
  }
}

@media screen and (min-width: 2560px) {
  .about-counter-text {
    font-size: 44px;
    line-height: 63.8px;
  }
}

.about-label-text {
  text-align: center;
  color: #141414;
  font-family: Lato;
  font-size: 22px;
  font-weight: 300;
  line-height: 31.9px;
}

@media screen and (min-width: 1440px) {
  .about-label-text {
    font-size: calc(1.25vw + 4px);
    line-height: calc(1.8125vw + 5.8px);
  }
}

@media screen and (min-width: 2560px) {
  .about-label-text {
    font-size: 36px;
    line-height: 52.2px;
  }
}

.about-section {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.about-section__body {
  width: 100%;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin: -20px;
  display: flex;
}

.about-section__achivment {
  flex: 0 0 20%;
  padding: 20px;
}

.achivment__image {
  width: 125px;
  height: 125px;
  background: no-repeat;
  margin: 0 auto;
}

.achivment__image--years {
  background-image: url("years-icon.ec47cad9.svg");
}

.achivment__image--locations {
  background-image: url("locations-icon.6b3f480e.svg");
}

.achivment__image--workers {
  background-image: url("workers-icon.0fdfe7ce.svg");
}

.achivment__image--clients {
  background-image: url("clients-icon.57b8c186.svg");
}

.achivment__image--projects {
  background-image: url("projects-icon.372f44ab.svg");
}

@media (max-width: 1270px) {
  .about-section__body {
    max-width: 675px;
  }
}

@media (max-width: 450px) {
  .about-section__achivment {
    flex: 0 0 100%;
  }
}

.abilities-label-text {
  color: #141414;
  text-align: center;
  font-family: Lato;
  font-size: 22px;
  font-weight: 300;
  line-height: 31.9px;
}

@media screen and (min-width: 1440px) {
  .abilities-label-text {
    font-size: calc(1.25vw + 4px);
    line-height: calc(1.8125vw + 5.8px);
  }
}

@media screen and (min-width: 2560px) {
  .abilities-label-text {
    font-size: 36px;
    line-height: 52.2px;
  }
}

.abilities-section {
  background-color: #fff;
  flex-direction: column;
  display: flex;
}

.abilities-section__body {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin: -40px;
  display: flex;
}

.abilities-section__ability {
  flex: 0 0 33.333%;
  padding: 40px;
}

.ability__image {
  width: 205px;
  height: 205px;
  background: no-repeat;
  margin: 0 auto;
}

.ability__image--web {
  background-image: url("web-icon.e201fb5d.svg");
}

.ability__image--gadget {
  background-image: url("gadget.506f7790.svg");
}

.ability__image--software {
  background-image: url("software-icon.8b237cf1.svg");
}

.ability__image--1c {
  background-image: url("1c-icon.139aef2e.svg");
}

.ability__image--realtime-systems {
  background-image: url("realtime-systems-icon.5a2bea8d.svg");
}

.ability__image--high-load-systems {
  background-image: url("high-load-systems-icon.0e64425e.svg");
}

.specialization-subtitle-text {
  color: #102d52;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 43.5px;
}

@media screen and (min-width: 1440px) {
  .specialization-subtitle-text {
    font-size: calc(1.25vw + 12px);
    line-height: calc(1.8125vw + 17.4px);
  }
}

@media screen and (min-width: 2560px) {
  .specialization-subtitle-text {
    font-size: 44px;
    line-height: 63.8px;
  }
}

.specialization-text {
  color: #141414;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 31.9px;
}

@media screen and (min-width: 1440px) {
  .specialization-text {
    font-size: calc(1.25vw + 4px);
    line-height: calc(1.8125vw + 5.8px);
  }
}

@media screen and (min-width: 2560px) {
  .specialization-text {
    font-size: 36px;
    line-height: 52.2px;
  }
}

.specialization-section {
  background-color: #c1eddf26;
  flex-direction: column;
  padding-top: 75px;
  padding-bottom: 75px;
  display: flex;
}

.specialization-section__body {
  flex-flow: column wrap;
  display: flex;
}

.specialization-section__specialization-block {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.specialization-block__title-container {
  width: 100%;
  align-items: center;
  display: flex;
}

.specialization-block__title-line {
  height: 1px;
  background-color: #102d521a;
  flex: 1 0 auto;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.specialization-block__title {
  text-align: start;
  margin-top: 50px;
  margin-bottom: 20px;
}

.specialization-block__body > ul {
  flex-flow: wrap;
  display: flex;
}

.specialization-block__body > ul > li {
  margin-top: 15px;
  margin-right: 30px;
  display: inline;
}

.projects-post-subtitle-text {
  color: #fff;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 43.5px;
}

@media screen and (min-width: 1440px) {
  .projects-post-subtitle-text {
    font-size: calc(1.25vw + 12px);
    line-height: calc(1.8125vw + 17.4px);
  }
}

@media screen and (min-width: 2560px) {
  .projects-post-subtitle-text {
    font-size: 44px;
    line-height: 63.8px;
  }
}

.projects-post-label-text {
  color: #fff;
  opacity: .5;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 29px;
}

@media screen and (min-width: 1440px) {
  .projects-post-label-text {
    font-size: calc(1.25vw + 2px);
    line-height: calc(1.8125vw + 2.9px);
  }
}

@media screen and (min-width: 2560px) {
  .projects-post-label-text {
    font-size: 34px;
    line-height: 49.3px;
  }
}

.projects-post-text {
  color: #fff;
  letter-spacing: .035em;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 31.9px;
}

@media screen and (min-width: 1440px) {
  .projects-post-text {
    font-size: calc(1.25vw + 4px);
    line-height: calc(1.8125vw + 5.8px);
  }
}

@media screen and (min-width: 2560px) {
  .projects-post-text {
    font-size: 36px;
    line-height: 52.2px;
  }
}

.projects-section {
  background-color: #fff;
  display: block;
}

.projects-section__mobile-posts {
  display: none;
}

.projects-section__post {
  min-height: 70vh;
  height: 100%;
  background-size: cover;
}

.projects-section__post_bg_1slide {
  background-color: red;
  background-image: linear-gradient(#15141667, #15141667), url("left.7856a3f6.jpeg");
}

.projects-section__post_bg_2slide {
  background-color: green;
  background-image: linear-gradient(#15141667, #15141667), url("middle.f7b5a884.jpeg");
}

.projects-section__post_bg_3slide {
  background-color: #00f;
  background-image: linear-gradient(#15141667, #15141667), url("right.72fd296c.jpeg");
}

.projects-section .post {
  flex-direction: column;
  padding: 7vw;
  display: flex;
}

.projects-section .post__header {
  margin-bottom: 3vw;
}

@media (max-width: 767px) {
  .projects-section {
    padding: 0;
  }

  .projects-section__slider {
    display: none;
  }

  .projects-section__mobile-posts {
    display: block;
  }
}

.contact-text-1 {
  color: #141414;
  text-align: center;
  max-width: 516px;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 31.9px;
}

@media screen and (min-width: 1440px) {
  .contact-text-1 {
    font-size: calc(1.25vw + 4px);
    line-height: calc(1.8125vw + 5.8px);
  }
}

@media screen and (min-width: 2560px) {
  .contact-text-1 {
    font-size: 36px;
    line-height: 52.2px;
  }
}

.contact-text-2 {
  color: #14141477;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20.3px;
}

@media screen and (min-width: 1440px) {
  .contact-text-2 {
    font-size: calc(.892857vw + 1.14286px);
    line-height: calc(1.29464vw + 1.65714px);
  }
}

@media screen and (min-width: 2560px) {
  .contact-text-2 {
    font-size: 24px;
    line-height: 34.8px;
  }
}

.contact-input-text {
  color: #141414;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 29px;
}

@media screen and (min-width: 1440px) {
  .contact-input-text {
    font-size: calc(1.25vw + 2px);
    line-height: calc(1.8125vw + 2.9px);
  }
}

@media screen and (min-width: 2560px) {
  .contact-input-text {
    font-size: 34px;
    line-height: 49.3px;
  }
}

.contact-input-text::placeholder {
  color: #14141477;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 29px;
}

@media screen and (min-width: 1440px) {
  .contact-input-text::placeholder {
    font-size: calc(1.25vw + 2px);
    line-height: calc(1.8125vw + 2.9px);
  }
}

@media screen and (min-width: 2560px) {
  .contact-input-text::placeholder {
    font-size: 34px;
    line-height: 49.3px;
  }
}

.contact-button-text {
  color: #fff;
  letter-spacing: .05em;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 34.8px;
}

@media screen and (min-width: 1440px) {
  .contact-button-text {
    font-size: calc(1.07143vw + 8.57143px);
    line-height: calc(1.55357vw + 12.4286px);
  }
}

@media screen and (min-width: 2560px) {
  .contact-button-text {
    font-size: 36px;
    line-height: 52.2px;
  }
}

.contact-section {
  background-color: #c1eddf26;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;
  padding-bottom: 75px;
  display: flex;
}

.contact-section > h2 {
  max-width: 516px;
  margin-bottom: 25px;
}

.contact-section__label {
  margin-bottom: 70px;
}

.contact-form {
  max-width: 516px;
  flex-direction: column;
  display: flex;
}

.contact-form__input {
  width: 100%;
  min-height: 45px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #102d521a;
  margin-bottom: 27px;
  padding: 20px;
}

.contact-form__politic {
  margin-bottom: 27px;
}

.contact-form__button {
  min-width: 255px;
  max-width: 400px;
  min-height: 55px;
  background-color: #102d52;
  border-radius: 5px;
  padding: 10px;
}

@media (min-width: 1800px) {
  .contact-section > h2, .contact-section__label, .contact-form {
    max-width: 1000px;
  }
}

.footer-title-text {
  color: #fff;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40.6px;
}

@media screen and (min-width: 1440px) {
  .footer-title-text {
    font-size: calc(1.42857vw + 7.42857px);
    line-height: calc(2.07143vw + 10.7714px);
  }
}

@media screen and (min-width: 2560px) {
  .footer-title-text {
    font-size: 44px;
    line-height: 63.8px;
  }
}

.footer-text {
  color: #fff;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26.1px;
}

@media screen and (min-width: 1440px) {
  .footer-text {
    font-size: calc(.892857vw + 5.14286px);
    line-height: calc(1.29464vw + 7.45714px);
  }
}

@media screen and (min-width: 2560px) {
  .footer-text {
    font-size: 28px;
    line-height: 40.6px;
  }
}

.footer {
  width: 100%;
  background-color: #282c34;
  padding: 50px 12.152vw;
}

.footer__title {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  display: flex;
}

.footer__title--muted {
  opacity: .7;
}

.footer__contacts-container {
  flex-flow: wrap;
  justify-content: center;
  margin: -15px;
  display: flex;
}

.footer__contacts {
  width: 220px;
  padding: 15px;
}

.footer__contacts > ul > li + li {
  margin-top: 8px;
}

.footer__icon {
  margin-left: 20px;
  position: relative;
}

.footer__icon--phone:before {
  content: url("phone.7612d15f.svg");
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: -20px;
}

.footer__icon--email:before {
  content: url("email.63d6c17a.svg");
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: -20px;
}

.footer__icon--map:before {
  content: url("map-marker.b5fc9a05.svg");
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: -18px;
}

@media (max-width: 1150px) {
  .footer__contacts {
    width: 300px;
  }
}

@media (min-width: 1450px) {
  .footer__contacts {
    width: 270px;
  }
}

@media (min-width: 1900px) {
  .footer__contacts {
    width: 320px;
  }
}

/*# sourceMappingURL=index.ec4c0cc6.css.map */
