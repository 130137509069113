@use "../utilities/variables";
@use "../utilities/mixins/fluid-text" as *;
@use "../utilities/variables" as var;

//Overall styles
html,
body {
  background-color: var.$color-bg-wrapper;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
}
.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  max-width: 100vw;
}

.content {
  background-color: var.$color-bg-primary;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &__section {
    width: 100%;
    padding: 0px 12.5vw;
    margin-top: 175px;
  }
}

.title {
  color: var.$color-text-title;
  font-weight: 500;
  @include fluid-type(1440px, 2560px, 36px, 50px);
  text-align: center;
  margin-bottom: 75px;
}
