@use "../utilities/mixins/fluid-text" as *;
@use "../utilities/variables" as var;

.header-nav-text {
  color: var.$color-text-2;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  @include fluid-type(1440px, 2560px, 18px, 28px);
}
.header-cmp-name-text {
  color: var.$color-text-2;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  @include fluid-type(1440px, 2560px, 20px, 34px);
}
.header-label-1 {
  color: var.$color-text-2;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 18px, 28px);
}
.header-label-2 {
  color: var.$color-text-2;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 25px, 38px);
  text-align: center;
}
.header-title-1 {
  color: var.$color-text-2;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  @include fluid-type(320px, 2560px, 43px, 96px);
  text-align: center;
}
.header-contacts-text {
  color: var.$color-text-2;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 18px, 28px);
}

.header {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-image: url("/src/assets/header/welcome-optimized.png");
  background-image: linear-gradient(#15141667, #15141667),
    url("/src/assets/header/welcome-optimized.png");

  display: grid;
  grid-template-areas:
    "company nav nav"
    "welcome welcome welcome"
    "... ... contacts";
  grid-template-rows: 100px 1fr 100px;
  grid-template-columns: auto 1fr auto;

  &__company {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: company;
    padding-left: 5vw;
    > :first-child {
      margin-top: 14px;
    }
  }
  &__nav {
    grid-area: nav;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5vw;
    > a {
      text-align: center;
      margin: 1em;
    }
  }
  &__welcome {
    padding: 0px 2em;
    grid-area: welcome;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > div {
      text-align: center;
      max-width: 700px;
    }
    :nth-child(1) {
      margin-bottom: 20px;
    }
    :nth-child(2) {
      padding-top: 20px;
      max-width: 550px;
      border-top: 1px solid var.$color-header-line;
    }
  }
  &__scroll-button {
    position: absolute;
    width: 44px;
    height: 28px;
    background-image: url("/src/assets/header/scroll-button.svg");
    left: 50%;
    bottom: 50px;
    margin-left: -22px;
    margin-bottom: -14px;
    cursor: pointer;
  }
  &__contacts {
    grid-area: contacts;
    padding-right: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 35px;
  }
}

.contacts {
  &__icon {
    position: relative;
  }
  &__icon--phone::before {
    position: absolute;
    left: -20px;
    width: 15px;
    height: 15px;
    content: url("/src/assets/header/phone-icon.svg");
  }
  &__icon--email::before {
    position: absolute;
    left: -20px;
    width: 15px;
    height: 15px;
    content: url("/src/assets/header/email-icon.svg");
  }
}

@media (max-width: 768px) {
  .header {
    grid-template-areas:
      "company"
      "welcome"
      "contacts";

    grid-template-columns: 1fr;

    &__nav {
      display: none;
    }
    &__welcome {
      :nth-child(2) {
        margin: 0px 20px;
      }
    }
    &__company {
      padding: 0px;
      > div {
        text-align: center;
      }
    }
    &__scroll-button {
      display: none;
    }
    &__contacts {
      padding: 0px 0px 0px 30px;
      justify-content: center;
      align-items: center;
      > div {
        text-align: center;
      }
    }
  }
}
@media (min-width: 1400px) {
  .header {
    &__welcome {
      > div {
        min-width: 1000px;
      }
    }
  }
}
@media (min-width: 1400px) {
  .header {
    &__welcome {
      > div {
        min-width: 800px;
      }
    }
  }
}
@media (min-width: 1900px) {
  .header {
    &__welcome {
      > div {
        min-width: 900px;
      }
    }
  }
}
@media (min-width: 2250px) {
  .header {
    &__welcome {
      > div {
        min-width: 1000px;
      }
    }
  }
}

@media (min-width: 1600px) {
  .contacts {
    &__icon--phone::before {
      left: -28px;
      width: 20px;
      height: 20px;
    }
    &__icon--email::before {
      left: -28px;
      width: 20px;
      height: 20px;
    }
  }
  .header__scroll-button {
    width: 66px;
    height: 42px;
    left: 50%;
    bottom: 75px;
    margin-left: -33px;
    margin-bottom: -25px;
  }
}
@media (min-width: 2000px) {
  .contacts {
    &__icon--phone::before {
      left: -28px;
      width: 20px;
      height: 20px;
    }
    &__icon--email::before {
      left: -28px;
      width: 23px;
      height: 23px;
    }
  }
}
@media (min-width: 2560px) {
  .header {
    grid-template-rows: 150px 1fr 150px;

    &__scroll-button {
      width: 99px;
      height: 63px;
      left: 50%;
      bottom: 112.5px;
      margin-left: -49.5px;
      margin-bottom: -32.5px;
    }
  }
  .contacts {
    &__icon--phone::before {
      left: -35px;
      width: 30px;
      height: 30px;
    }
    &__icon--email::before {
      left: -35px;
      width: 30px;
      height: 30px;
    }
  }
}
