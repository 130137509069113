@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
