@mixin card($img-width, $img-height) {
  &__image {
    width: $img-width;
    height: $img-height;
    margin: 0 auto;
    background: no-repeat;
  }
  &__label {
  }
  &__counter {
  }
}
