@use "./base/all";
@use "./layout/_overall-layout";
@use "./components/chief-slider";

@use "./layout/header";
@use "./layout/about-section";
@use "./layout/abilities-section";
@use "./layout/specialization-section";
@use "./layout/projects-section";
@use "./layout/contact-section";

@use "./layout/footer";
