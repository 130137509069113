@use "../utilities/variables" as var;
@use "../components/card" as *;
@use "../utilities/mixins/fluid-text" as *;

.about-counter-text {
  font-weight: 500;
  @include fluid-type(1440px, 2560px, 30px, 44px);
  text-align: center;
  color: var.$color-text-1;
}
.about-label-text {
  font-family: Lato;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 22px, 36px);
  text-align: center;
  color: var.$color-text-label-1;
}

.about-section {
  background-color: var.$color-bg-primary;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: -20px;
  }
  &__achivment {
    flex: 0 0 20%;
    padding: 20px;
  }
}
.achivment {
  @include card($img-width: 125px, $img-height: 125px);

  &__image--years {
    background-image: url("/src/assets/content/about/years-icon.svg");
  }
  &__image--locations {
    background-image: url("/src/assets/content/about/locations-icon.svg");
  }
  &__image--workers {
    background-image: url("/src/assets/content/about/workers-icon.svg");
  }
  &__image--clients {
    background-image: url("/src/assets/content/about/clients-icon.svg");
  }
  &__image--projects {
    background-image: url("/src/assets/content/about/projects-icon.svg");
  }
}
@media (max-width: 1270px) {
  .about-section {
    &__body {
      max-width: 675px;
    }
  }
}
@media (max-width: 450px) {
  .about-section {
    &__achivment {
      flex: 0 0 100%;
    }
  }
}
