/*!
* chiefSlider
*   site: https://itchief.ru/javascript/slider
*   github: https://github.com/itchief/ui-components
*
* Copyright 2018-2021 Alexander Maltsev
* Licensed under MIT (https://github.com/itchief/ui-components/blob/master/LICENSE)
*/

.slider {
  position: relative;
}

.slider__container {
  overflow: hidden;
}

.slider__wrapper {
  /*overflow: hidden;*/
}

.slider__items {
  display: flex;
  transition: transform 0.5s ease;
}

.slider_disable-transition {
  transition: none;
}

.slider__item {
  flex: 0 0 100%;
  max-width: 100%;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider__item {
  background-color: fff;
}

/* кнопки влево и вправо */

.slider__control {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  color: #fff;
  text-align: center;
  height: 50px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.2);
}

.slider__control_hide {
  display: none;
}

.slider__control[data-slide="prev"] {
  left: 0;
}

.slider__control[data-slide="next"] {
  right: 0;
}

.slider__control:hover,
.slider__control:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background: rgba(0, 0, 0, 0.3);
}

.slider__control::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.slider__control[data-slide="prev"]::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.slider__control[data-slide="next"]::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

/* индикаторы */

.slider__indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
}

.slider__indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 5px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  background-clip: padding-box;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.slider__indicators li.active {
  background-color: rgba(255, 255, 255, 0.9);
}
