@use "../utilities/mixins/fluid-text" as *;
@use "../utilities/variables" as var;

.footer-title-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  @include fluid-type(1440px, 2560px, 28px, 44px);
  color: var.$color-text-footer;
}

.footer-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 18px, 28px);
  color: var.$color-text-footer;
}

.footer {
  background-color: var.$color-bg-footer;
  width: 100%;
  padding: 50px 12.152vw;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    flex-direction: column;
    text-align: center;
    &--muted {
      opacity: 0.7;
    }
  }

  &__contacts-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -15px -15px;
  }
  &__contacts {
    > ul > li + li {
      margin-top: 8px;
    }
    width: 220px;
    padding: 15px;
  }
  &__icon {
    position: relative;
    margin-left: 20px;
    &--phone::before {
      position: absolute;
      left: -20px;
      top: 0px;
      content: url("/src/assets/footer/phone.svg");
      width: 15px;
      height: 15px;
    }
    &--email::before {
      position: absolute;
      left: -20px;
      top: 0px;
      content: url("/src/assets/footer/email.svg");
      width: 15px;
      height: 15px;
    }
    &--map::before {
      position: absolute;
      left: -18px;
      top: 0px;
      content: url("/src/assets/footer/map-marker.svg");
      width: 15px;
      height: 15px;
    }
  }
}
@media (max-width: 1150px) {
  .footer__contacts {
    width: 300px;
  }
}
@media (min-width: 1450px) {
  .footer__contacts {
    width: 270px;
  }
}
@media (min-width: 1900px) {
  .footer__contacts {
    width: 320px;
  }
}
