$color-bg-wrapper: #fff;
$color-bg-primary: #fff;
$color-bg-secondary: #c1eddf26;
$color-bg-footer: #282c34;

$color-button: #102d52;
$color-header-line: rgba(255, 255, 255, 0.45);
$color-projects-line: #102d521a;

$color-text-1: #141414;
$color-text-2: #fff;
$color-text-header: #fff;
$color-text-title: #141414;
$color-text-subtitle: #102d52;
$color-text-label-1: #141414;
$color-text-label-2: #14141477;
$color-text-specializ-text: #141414;
$color-text-footer: #fff;
$color-text-input-placeholder: #14141477;
