@use "../utilities/mixins/fluid-text" as *;
@use "../utilities/variables" as var;

.projects-post-subtitle-text {
  color: var.$color-text-2;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  @include fluid-type(1440px, 2560px, 30px, 44px);
}
.projects-post-label-text {
  color: var.$color-text-2;
  opacity: 0.5;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 20px, 34px);
}
.projects-post-text {
  color: var.$color-text-2;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 22px, 36px);
  letter-spacing: 0.035em;
}

.projects-section {
  background-color: var.$color-bg-primary;
  display: block;
  &__body {
  }
  &__mobile-posts {
    display: none;
  }
  &__post {
    min-height: 70vh;
    height: 100%;
    background-size: cover;
    &_bg_1slide {
      background-color: red;
      background-image: url("/src/assets/content/posts/left.jpeg");
      background-image: linear-gradient(#15141667, #15141667),
        url("/src/assets/content/posts/left.jpeg");
    }
    &_bg_2slide {
      background-color: green;
      background-image: url("/src/assets/content/posts/middle.jpeg");
      background-image: linear-gradient(#15141667, #15141667),
        url("/src/assets/content/posts/middle.jpeg");
    }
    &_bg_3slide {
      background-color: blue;
      background-image: url("/src/assets/content/posts/right.jpeg");
      background-image: linear-gradient(#15141667, #15141667),
        url("/src/assets/content/posts/right.jpeg");
    }
  }

  .post {
    padding: 7vw;
    display: flex;
    flex-direction: column;

    &__header {
      margin-bottom: 3vw;
    }

    &__body {
    }
  }
}
@media (max-width: 767px) {
  .projects-section {
    padding: 0px;
    &__slider {
      display: none;
    }
    &__mobile-posts {
      display: block;
    }
  }
}
