@use "../utilities/variables" as var;
@use "../utilities/mixins/fluid-text" as *;

.specialization-subtitle-text {
  color: var.$color-text-subtitle;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  @include fluid-type(1440px, 2560px, 30px, 44px);
}
.specialization-text {
  color: var.$color-text-specializ-text;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 22px, 36px);
}

.specialization-section {
  background-color: var.$color-bg-secondary;
  display: flex;
  flex-direction: column;
  padding-top: 75px;
  padding-bottom: 75px;
  &__body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  &__specialization-block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.specialization-block {
  &__title-container {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &__title-line {
    margin-bottom: 20px;
    margin-top: 50px;
    margin-left: 20px;
    flex: 1 0 auto;
    height: 1px;
    background-color: var.$color-projects-line;
  }
  &__title {
    margin-bottom: 20px;
    margin-top: 50px;
    text-align: start;
  }
  &__body {
    > ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > li {
        display: inline;
        margin-right: 30px;
        margin-top: 15px;
      }
    }
  }
}
