@use "../utilities/mixins/fluid-text" as *;
@use "../utilities/variables" as var;

.contact-text-1 {
  color: var.$color-text-1;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 22px, 36px);
  text-align: center;
  max-width: 516px;
}
.contact-text-2 {
  color: var.$color-text-label-2;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 14px, 24px);
}
.contact-input-text {
  color: var.$color-text-1;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 20px, 34px);
}
.contact-input-text::placeholder {
  color: var.$color-text-input-placeholder;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 20px, 34px);
}
.contact-button-text {
  color: var.$color-text-2;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  @include fluid-type(1440px, 2560px, 24px, 36px);
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.contact-section {
  padding-top: 75px;
  padding-bottom: 75px;
  & > h2 {
    margin-bottom: 25px;
    max-width: 516px;
  }
  background-color: var.$color-bg-secondary;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__label {
    margin-bottom: 70px;
  }
  &__form {
  }
}

.contact-form {
  max-width: 516px;
  display: flex;
  flex-direction: column;
  &__input {
    margin-bottom: 27px;
    width: 100%;
    min-height: 45px;

    background: var.$color-bg-primary;
    border: 1px solid rgba(16, 45, 82, 0.1);
    box-sizing: border-box;

    padding: 20px;
  }
  &__politic {
    margin-bottom: 27px;
  }
  &__button {
    min-width: 255px;
    max-width: 400px;
    min-height: 55px;
    border-radius: 5px;
    background-color: var.$color-button;
    padding: 10px;
  }
}
@media (min-width: 1800px) {
  .contact-section {
    & > h2 {
      max-width: 1000px;
    }
    &__label {
      max-width: 1000px;
    }
    &__input {
    }
  }
  .contact-form {
    max-width: 1000px;
  }
}
