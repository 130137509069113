@use "../utilities/mixins/fluid-text" as *;
@use "../components/card" as *;
@use "../utilities/variables" as var;

.abilities-label-text {
  color: var.$color-text-label-1;
  font-family: Lato;
  font-weight: 300;
  @include fluid-type(1440px, 2560px, 22px, 36px);
  text-align: center;
}

.abilities-section {
  background-color: var.$color-bg-primary;
  display: flex;
  flex-direction: column;
  &__body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: -40px;
  }
  &__ability {
    flex: 0 0 33.333%;
    padding: 40px;
  }
}

.ability {
  @include card($img-width: 205px, $img-height: 205px);

  &__image--web {
    background-image: url("/src/assets/content/abilities/web-icon.svg");
  }
  &__image--gadget {
    background-image: url("/src/assets/content/abilities/gadget.svg");
  }
  &__image--software {
    background-image: url("/src/assets/content/abilities/software-icon.svg");
  }
  &__image--1c {
    background-image: url("/src/assets/content/abilities/1c-icon.svg");
  }
  &__image--realtime-systems {
    background-image: url("/src/assets/content/abilities/realtime-systems-icon.svg");
  }
  &__image--high-load-systems {
    background-image: url("/src/assets/content/abilities/high-load-systems-icon.svg");
  }
}
